var tablet_breakpoint = 768;
var laptop_breakpoint = 992;
var desktop_breakpoint = 1200;
//Breakpoints as per _breakpoints.scss

var mobileAttr = 'mobile';
var tabletAttr = 'tablet';
var laptopAttr = 'laptop';
var desktopAttr = 'desktop';

$(document).ready(function () {

    function applyBreakpointImage() {
        var windowWidth = $(window).width();
        var breakpoint = mobileAttr;
        if (windowWidth >= tablet_breakpoint && windowWidth < laptop_breakpoint) {
            breakpoint = tabletAttr;
        } else if (windowWidth >= laptop_breakpoint && windowWidth < desktop_breakpoint) {
            breakpoint = laptopAttr;
        }else if (windowWidth >= desktop_breakpoint) {
            breakpoint = desktopAttr;
        }
http://localhost:61065/../../../../Content
        $('.breakpoint-image').each(function () {
            var breakpointSrc = $(this).data(breakpoint);

            if (breakpointSrc == null && $(this).data(tabletAttr) != null) {
                breakpointSrc = $(this).data(tabletAttr);
            }else if (breakpointSrc == null && $(this).data(laptopAttr) != null) {
                breakpointSrc = $(this).data(laptopAttr);
            } else if (breakpointSrc == null && $(this).data(desktopAttr) != null) {
                breakpointSrc = $(this).data(desktopAttr);
            }else if (breakpointSrc == null){
                breakpointSrc = $(this).data(mobileAttr);
            }

            if (breakpointSrc != null) {
                $(this).css('background-image', 'url("'+breakpointSrc+'")');
            }
        });
    }

    applyBreakpointImage();

    $(window).resize(function () {
        applyBreakpointImage();
    });
    
});